<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.fullName)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.fullName }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Оновити</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <!-- <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button> -->
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Username"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="userData.username"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="ПІБ"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.fullName"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="E-mail"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Status"
            label-for="user-status"
          >
            <v-select
              v-model="userData.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Роль"
            label-for="user-role"
          >
            <v-select
              v-model="userData.role"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Company"
            label-for="company"
          >
            <b-form-input
              id="company"
              v-model="userData.company"
            />
          </b-form-group>
        </b-col> -->

      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->
    <!-- <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card> -->

    <b-row>

      <!-- Field: Birth Date -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="Дата народження"
          label-for="birth-date"
        >
          <flat-pickr
            v-model="userDataInfo.dob"
            class="form-control"
            :config="{ dateFormat: 'd-m-Y'}"
            placeholder="DD-MM-YYYY"
          />
        </b-form-group>
      </b-col>

      <!-- Field: Mobile -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="Номер телефону"
          label-for="mobile"
        >
          <b-form-input
            id="mobile"
            v-model="userDataInfo.mobile"
          />
        </b-form-group>
      </b-col>

      <!-- Field: Website -->
      <!-- <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="Website"
          label-for="website"
        >
          <b-form-input
            id="website"
            v-model="userDataInfo.website"
          />
        </b-form-group>
      </b-col> -->

      <!-- Field: Language -->
      <!-- <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="Language"
          label-for="language"
        >
          <v-select
            v-model="userDataInfo.language"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="languageOptions"
            :clearable="false"
            input-id="language"
          />
        </b-form-group>
      </b-col> -->

      <!-- Field: Gender -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="Стать"
          label-for="gender"
          label-class="mb-1"
        >
          <b-form-radio-group
            id="gender"
            v-model="userDataInfo.gender"
            :options="genderOptions"
            value="male"
          />
        </b-form-group>
      </b-col>

      <!-- Field: Contact Options -->
      <!-- <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="Contact Options"
          label-for="contact-options"
          label-class="mb-1"
        >
          <b-form-checkbox-group
            id="contact-options"
            v-model="userDataInfo.contactOptions"
            :options="contactOptionsOptions"
          />
        </b-form-group>
      </b-col> -->
    </b-row>

      <!-- Header: Personal Info -->
    <div class="d-flex mt-2">
    <feather-icon
      icon="MapPinIcon"
      size="19"
    />
    <h4 class="mb-0 ml-50">
      Адреса
    </h4>
    </div>

      <!-- Form: Personal Info Form -->
    <b-row class="mt-1">

      <!-- Field: City -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="Місто"
          label-for="city"
        >
          <b-form-input
            id="city"
            v-model="userDataInfo.city"
          />
        </b-form-group>
      </b-col>

      <!-- Field: Street -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="Вулиця"
          label-for="street"
        >
          <b-form-input
            id="street"
            v-model="userDataInfo.street"
          />
        </b-form-group>
      </b-col>

      <!-- Field: Building -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="Будинок"
          label-for="building"
        >
          <b-form-input
            id="building"
            v-model="userDataInfo.building"
            placeholder=""
          />
        </b-form-group>
      </b-col>

      <!-- Field: Flat -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="Квартира"
          label-for="flat"
        >
          <b-form-input
            id="flat"
            v-model="userDataInfo.flat"
            placeholder=""
          />
        </b-form-group>
      </b-col>

      <!-- Field: Postcode -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="Поштовий індекс"
          label-for="postcode"
        >
          <b-form-input
            id="postcode"
            v-model="userDataInfo.postcode"
            type="number"
            placeholder="597626"
          />
        </b-form-group>
      </b-col>


      <!-- Field: State -->
      <!-- <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="State"
          label-for="state"
        >
          <b-form-input
            id="state"
            v-model="userDataInfo.state"
            placeholder="Manhattan"
          />
        </b-form-group>
      </b-col> -->

      <!-- Field: Country -->
      <!-- <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="Country"
          label-for="country"
        >
          <b-form-input
            id="country"
            v-model="userDataInfo.country"
            placeholder="United States"
          />
        </b-form-group>
      </b-col> -->
    </b-row>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Зберегти зміни
    </b-button>
    <!-- <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button> -->
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormRadioGroup,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormRadioGroup,
    vSelect,
    flatPickr,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()

    const roleOptions = [
      { label: 'Адміністратор', value: 'administrator' },
      { label: 'Менеджер', value: 'manager' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    const userDataInfo = ref({
      dob: null,
      mobile: '+380681234567',
      website: 'https://rowboat.com/insititious/Angelo',
      language: 'French',
      gender: 'female',
      contactOptions: ['Email', 'Message'],
      addressLine1: 'A-65, Belvedere Streets',
      addressLine2: '',
      city: 'Київ',
      street: 'Хрещатик',
      building: 10,
      flat: 23,
      postcode: '10023',
      state: '',
      country: '',
    })

    const languageOptions = [
      'English',
      'Spanish',
      'French',
      'Russian',
      'German',
      'Arabic',
      'Sanskrit',
    ]

    const genderOptions = [
      { text: 'Чоловік', value: 'male' },
      { text: 'Жінка', value: 'female' },
    ]

    const contactOptionsOptions = ['Email', 'Message', 'Phone']


    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,


      userDataInfo,
      languageOptions,
      genderOptions,
      contactOptionsOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
